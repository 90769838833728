<template>
  <v-app-bar
      id="core-app-bar"
      app
      height="90"
      flat
      color="white"
  >
    <v-icon class="mr-4" @click="$store.state.drawer = !$store.state.drawer">mdi-menu</v-icon>
    <router-link :to="{name: 'home'}">
      <v-img :src="`${$store.getters.frontUrl}/assets/img/logo.svg`" :width="130"></v-img>
    </router-link>

    <v-spacer/>

    <div v-if="$store.getters.secondUser">
      <v-btn
          class="ml-2"
          min-width="0"
          text
          @click="backToAdmin"
      >
        <span v-if="$vuetify.breakpoint.mdAndUp" class="text-caption">{{ $t('logout_admin')}}</span>
        <v-icon small class="ml-2">mdi-logout</v-icon>
      </v-btn>
    </div>

    <div v-if="!$store.getters.secondUser">
      <v-btn
          min-width="0"
          text
          @click="logout"
          :loading="logouting"
          :disabled="logouting"
      >
        <span v-if="$vuetify.breakpoint.mdAndUp" class="text-caption">{{ $t('logout') }}</span>
        <v-icon small class="ml-2">mdi-logout</v-icon>
      </v-btn>
    </div>

    <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
            @click="$router.push({name: 'profileNotifications'})"
        >
          <v-icon small>mdi-bell-outline</v-icon>
        </v-btn>
      </template>
    </v-menu>
    <v-btn
        class="ml-2"
        min-width="0"
        text
        @click="$router.push({name: 'profile'})"
    >
      <v-avatar size="48" class="elevation-5">
        <v-img :src="$store.getters.user.user.avatar ? $store.getters.user.user.avatar+'?'+ Date.now() : '/assets/img/avatar.png'">
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-5 text-body-1">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import {VHover, VListItem} from "vuetify/lib";

// Utilities
import {mapState, mapMutations} from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(
                  VListItem,
                  {
                    attrs: this.$attrs,
                    class: {
                      "black--text": !hover,
                      "white--text primary elevation-12": hover
                    },
                    props: {
                      activeClass: "",
                      dark: hover,
                      link: true,
                      ...this.$attrs
                    }
                  },
                  this.$slots.default
              );
            }
          }
        });
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    notifications: [],
    logouting: false
  }),
  computed: {
    ...mapState(["drawer"])
  },
  mounted() {
    this.getLastNotifications()
  },
  methods: {
    getLastNotifications() {
      this.$store.dispatch('getLastNotifications')
          .then(resp => {
            this.notifications = resp
          })
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    logout() {
      this.logouting = true
      this.$store.dispatch('logout')
          .then(resp => {
            this.$router.push({name: 'home'})
          })
          .catch(err => {
            this.logouting = false
            this.$helpers.showError(err)
          })
    },
    backToAdmin() {
      this.$store.commit('clear_second_user')
      window.location = this.$store.getters.frontUrl + '/admin/users/list'
    }
  }
};
</script>
